import React from "react";
// import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";


function Contact() {
  return (
    <div className="body-white-color-2">
      <h4>Contact</h4>
      <ToastContainer toastClassName="toast-right" />
    </div>
  );
}

export default Contact;
