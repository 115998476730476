import React from "react";
// import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";

// icon

import {  FaRegEdit } from "react-icons/fa";
import { CiHeart, CiFlag1 } from "react-icons/ci";

function ProfileHistory() {
  return (
    <body>
      {/* <!--============ HEADER ===========--> */}
      <header className="profile-header">
        {/* <!--banner---> */}
        <div className="profile-header-banner">
          {/* <!--top-content--> */}
          <div className="profile-header-up-content u-flex-box u-align-items-center">
            {/* <!--text--> */}
            <p className="h4-title white-color">51 Participated battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">62 Created battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">Battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">$2,500 Wins</p>
          </div>
          {/* <!--bottom-content--> */}
          {/* <!--edite-button--> */}
          <button className="edite-btn">
            {/* <!--icon--> */}

            <FaRegEdit className="fa-solid fa-pen-to-square" />
          </button>
        </div>
        {/* <!--info--> */}
        <div className="profile-header-info u-flex-box u-align-items-center u-justify-content-space-between">
          {/* <!--profile-box--> */}
          <div className="profile-wrapper">
            {/* <!--img--> */}
            <div className="profile-img u-margin-bottom-small">
              <img
                src="https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg"
                alt="img"
                className="profile-img-content u-img-style"
              />
            </div>
            {/* <!--player-name--> */}
            <h3 class="player-name h3-title white-color">Player name</h3>
            {/* <!--text--> */}
            <p className="date-text text-v2 white-color">Created in 21 june</p>
          </div>
          {/* <!--btns-wrapper--> */}
          <div className="info-box-btns-wrapper u-flex-box u-align-items-center">
            {/* <!--btn--> */}
            <button className="profile-btn btn-primary-color">
              <CiHeart className="fa-solid fa-heart" />
            </button>
            {/* <!--btn--> */}
            <button className="profile-btn btn-primary-color">
              <i className="fa-solid fa-flag"></i>
              <CiFlag1 className="fa-solid fa-flag" />
            </button>
          </div>
        </div>
      </header>
      {/* <!--=========main============--> */}
      <main className="profile-main">
        {/* <!--========profile-main-wrapper========--> */}
        <div className="profile-main-wrapper u-flex-box">
          {/* <!--aside--> */}
          <aside className="profile-aside">
            {/* <!--list--> */}
            <ul className="profile-option-list">
              {/* <!--item--> */}
              <li className="profile-option-item">
                <a
                  href="/"
                  className="profile-option-link u-flex-box u-align-items-center"
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-gamepad"></i>
                  Invite friends
                </a>
              </li>
              {/* <!--item--> */}
              <li className="profile-option-item">
                <a
                  href="/"
                  className="profile-option-link u-flex-box u-align-items-center profile-option-link-active"
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-clock-rotate-left"></i>
                  History
                </a>
              </li>
              {/* <!--item--> */}
              <li className="profile-option-item ">
                <a
                  href="./profile-favorite.html"
                  className="profile-option-link u-flex-box u-align-items-center "
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-heart"></i>
                  Favorites
                </a>
              </li>
            </ul>
          </aside>
          {/* <!--content--> */}
          <div className="profile-main-content">
            {/* <!--mobile-list--> */}
            <ul className="profile-mobile-list u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-medium">
              <li class="profile-mobile-item">
                <a
                  href="/"
                  class="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-gamepad"></i>
                  Invite friends
                </a>
              </li>
              <li class="profile-mobile-item">
                <a
                  href="/"
                  class="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-heart"></i>
                  Favorites
                </a>
              </li>
              <li class="profile-mobile-item">
                <a
                  href="/"
                  class="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-clock-rotate-left"></i>
                  History
                </a>
              </li>
            </ul>

            {/* <!--labels--> */}
            <div class="history-labels u-flex-box u-align-items-center u-margin-bottom-medium">
              {/* <!--input--> */}
              <input
                type="radio"
                name="history-input-radio"
                class="history-input"
                id="history-input-1"
                hidden
                checked
              />
              {/* <!--label--> */}
              <label
                for="history-input-1"
                id="history-label-1"
                class="history-label-btn"
              >
                All battles
              </label>
              {/* <!--input--> */}
              <input
                type="radio"
                name="history-input-radio"
                class="history-input"
                id="history-input-2"
                hidden
              />
              {/* <!--label--> */}
              <label
                for="history-input-2"
                id="history-label-2"
                class="history-label-btn"
              >
                Participated battles
              </label>
              {/* <!--input--> */}
              <input
                type="radio"
                name="history-input-radio"
                class="history-input"
                id="history-input-3"
                hidden
              />
              {/* <!--label--> */}
              <label
                for="history-input-3"
                id="history-label-3"
                class="history-label-btn"
              >
                Created battles
              </label>
              {/* <!--input--> */}
              <input
                type="radio"
                name="history-input-radio"
                class="history-input"
                id="history-input-4"
                hidden
              />
              {/* <!--label--> */}
              <label
                for="history-input-4"
                id="history-label-4"
                class="history-label-btn"
              >
                Games won
              </label>
            </div>

            {/* <!-- profile-boxes-wrapper --> */}
            <div class="profile-boxes-wrapper">
              {/* <!--text-box--> */}
              <div class="history-create-battle-msg u-flex-box u-align-items-center u-justify-content-center">
                {/* <!--title--> */}
                <h3 class="h3-title white-color u-text-align-center">
                  You haven’t start the battle yet
                </h3>
                {/* <!--btn--> */}
                <a href="/" class="btn btn-primary-color">
                  Create battle
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </body>
  );
}

export default ProfileHistory;
