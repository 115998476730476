import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/typography.css'
import '../assets/css/variables.css'
import { useNavigate } from 'react-router-dom'

import { FaApple, FaFacebookF, FaGoogle } from 'react-icons/fa'

function Register() {
  // State برای ذخیره ورودی‌های کاربر
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const navigate = useNavigate()

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const handleRegister = (e) => {
    e.preventDefault()

    if (!isChecked) {
      toast.error('Accept rules necessary')
      return
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match')
      return
    }

    let data = JSON.stringify({
      email: email,
      password: password,
      public_name: username,
      type: 'email',
      referralCode: referralCode || undefined, // ارسال رفرال کد تنها در صورتی که مقدار داشته باشد
    })

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://redjayco.com/api/register',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Register success', {
            onClose: () => navigate('/login'), // هدایت به صفحه لاگین بعد از بسته شدن toast
          })
        } else {
          toast.error(response.data.message || 'An error occurred')
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || 'An error occurred')
      })
  }

  // برگشت به خانه

  const handleBackClick = () => {
    navigate('/')
  }

  // نشان دادن پسورد

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  return (
    <main>
      {/*=========form-section==============*/}
      <section className='form-section register-form-section'>
        {/*form-wrapper*/}
        <div className='form-wrapper making-center-position'>
          <button className='btn-back' onClick={handleBackClick}>
            Back
          </button>
          {/*form*/}
          <form
            action='#'
            className='form-v1 login-form making-center-position'
            onSubmit={handleRegister}
          >
            {/*title*/}
            <h1 className='h1-title-secondary-color form-v1-title u-margin-bottom-form'>
              Register
            </h1>
            {/*row-wrapper*/}
            <div className='form-row-wrapper u-margin-bottom-form u-flex-box u-align-items-center'>
              {/*form-group*/}
              <div className='form-group-v1'>
                <input
                  type='email'
                  name='email'
                  className='form-group-v1-input'
                  id='form-group-v1-email'
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  htmlFor='form-group-v1-email'
                  className='form-group-v1-label'
                >
                  Email
                </label>
              </div>
              <div className='form-group-v1'>
                <input
                  type='text'
                  name='username'
                  className='form-group-v1-input'
                  id='form-group-v1-username'
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label
                  htmlFor='form-group-v1-username'
                  className='form-group-v1-label'
                >
                  Username
                </label>
              </div>
            </div>
            <div className='form-row-wrapper u-margin-bottom-form u-flex-box u-align-items-center'>
              <div className='form-group-v1'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  className='form-group-v1-input'
                  id='form-group-v1-password'
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label
                  htmlFor='form-group-v1-password'
                  className='form-group-v1-label'
                >
                  Password
                </label>
                <button
                  type='button'
                  className='show-password-button'
                  onClick={toggleShowPassword}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
              <div className='form-group-v1'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name='confirm-password'
                  className='form-group-v1-input'
                  id='form-group-v1-confirm-password'
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <label
                  htmlFor='form-group-v1-confirm-password'
                  className='form-group-v1-label'
                >
                  Confirm Password
                </label>
                <button
                  type='button'
                  className='show-password-button'
                  onClick={toggleShowConfirmPassword}
                >
                  {showConfirmPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>
            <div className='form-group-v1 u-margin-bottom-form'>
              <input
                type='text'
                name='referral-code'
                className='form-group-v1-input'
                id='form-group-v1-referral-code'
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
              <label
                htmlFor='form-group-v1-referral-code'
                className='form-group-v1-label'
              >
                Enter referral code (optional)
              </label>
            </div>
            <div className='form-group-v1 u-margin-bottom-form u-flex-box u-align-items-center u-justify-content-space-between'>
              <div className='remember-me-wrapper u-flex-box u-align-items-center'>
                <input
                  type='checkbox'
                  className='remember-check-box'
                  id='remember-check-box'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor='remember-check-box' className='form-text-v1'>
                  I agree with privacy and policy
                </label>
              </div>
            </div>
            <div className='form-group-lines-wrapper u-margin-bottom-form grid-box grid-3-columns u-align-items-center'>
              <span className='form-v1-line'></span>
              <p className='form-text-v2 form-text-v2-bold'>Register with</p>
              <span className='form-v1-line'></span>
            </div>
            <div className='form-v1-social-wrapper u-margin-bottom-form u-flex-box u-align-items-center u-justify-content-center'>
              <a
                href='/'
                className='form-v1-social-link form-v1-social-link-apple'
              >
                <FaApple className='fa-brands fa-apple' />
              </a>
              <a href='/' className='form-v1-social-link'>
                <FaFacebookF className='fa-brands fa-facebook-f' />
              </a>
              <a href='/' className='form-v1-social-link'>
                <FaGoogle className='fa-brands fa-google' />
              </a>
            </div>
            <div className='form-group-v1 u-margin-bottom-form'>
              <button
                type='submit'
                className='form-btn-v1 u-flex-box u-align-items-center u-justify-content-center'
              >
                Register
              </button>
            </div>
            <div className='form-group-v1 form-down-text-wrapper u-flex-box u-justify-content-center u-align-items-center'>
              <p className='form-text-v2'>Already have an account?</p>
              <a href='/login' className='form-v1-link-primary-color'>
                Login
              </a>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
    </main>
  )
}

export default Register
