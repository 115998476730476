import React from "react";
// import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";

import union from "../assets/images/Union.svg";

function BattlePerview() {
  return (
    <div className="body-battle-perview-bakground">
      {/* <!--battle-perview-section-->*/}
      <section className="perview-section">
        {/* <!--battle-perview-box-wrapper--> */}
        <div className="battle-perview-main-wrapper u-flex-box u-align-items-center u-justify-content-center">
          {/* <!--box--> */}
          <div className="battle-perview-box">
            {/* <!--background-img--> */}
            <img
              src={union}
              alt="img"
              className="u-img-style battle-perview-bakground-img"
            />
            {/* <!--content--> */}
            <div className="battle-perview-box-content">
              {/* <!--title-box--> */}
              <div className="perview-title-box u-flex-box u-align-items-center u-justify-content-center">
                {/* <!--title--> */}
                <h4 class="perview-title h4-title secondary-color">
                  Game prediction
                </h4>
              </div>
              {/* <!--battle-title--> */}
              <h4 className="h4-title white-color battle-title-v1 u-margin-bottom-big u-text-align-center">
                Title (Fance & Germany game)
              </h4>
              {/* <!--row-wrapper--> */}
              <div className="battle-perview-row-wrapper u-flex-box u-align-items-center">
                {/* <!--profile-box--> */}
                <div className="perview-profile-wrapper u-flex-box u-align-items-center u-justify-content-center">
                  {/* <!--group-name--> */}
                  <h5 className="perview-group-name h4-title white-color">
                    Group A
                  </h5>
                  {/* <!--profile-wrapper--> */}
                  <div className="perview-profile-img-wrapper">
                    <img
                      src="https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg"
                      alt="img"
                      class="u-img-style"
                    />
                  </div>
                  {/* <!--group-name--> */}
                  <h5 className="perview-group-name text-v1 white-color">
                    Player name
                  </h5>
                </div>
                {/* <!--text-box--> */}
                <div className="perview-text-box">
                  {/* <!--title-box--> */}
                  <div className="perview-text-box-title-box u-flex-box u-align-items-center u-justify-content-center">
                    {/* <!--title--> */}
                    <h5 className="h5-title black-color">Win condition</h5>
                  </div>
                  {/* <!--text--> */}
                  <p class="text-v1 white-color">
                    Lorem Ipsum, sometimes referred to as 'lipsum', is the
                    placeholder text used in design when creating content. It
                    helps designers plan out where the content will sit
                  </p>
                </div>
                {/* <!--bottom-box--> */}
                <div className="battle-perview-bottom-box u-flex-box u-align-items-center">
                  {/* <!--text--> */}
                  <p className="batte-perview-bottom-text u-flex-box u-align-items-center u-justify-content-center">
                    July / 13 / 2024
                  </p>
                  {/* <!--text--> */}
                  <p className="batte-perview-bottom-text u-flex-box u-align-items-center u-justify-content-center">
                    23 : 11 :24
                  </p>
                  {/* <!--middle-line--> */}
                  <span className="middle-line making-center-position"></span>
                </div>
              </div>
            </div>
          </div>
          {/* <!--confirmation--> */}
          <div clclassNameass="confirmation-box">
            {/* <!--title--> */}
            <h3 class="confirmation-box-title h3-title white-color u-margin-bottom-small u-text-align-center">
              Do you want to continue?
            </h3>
            {/* <!--btns-wrapper--> */}
            <div class="perview-btns-wrapper u-flex-box u-align-items-center u-justify-content-center">
              {/* <!--button--> */}
              <button class="btn perdiction-main-btn btn-white-color">
                Reject
              </button>
              {/* <!--button--> */}
              <button class="btn perdiction-main-btn btn-active btn-white-color">
                Accept
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer toastClassName="toast-right" />
    </div>
  );
}

export default BattlePerview;
