import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";
import { useNavigate } from "react-router-dom";

// img

import union from "../assets/images/Union.svg";

// icon

import {
  FaBell,
  FaGamepad,
  FaClock,
  FaUser,
  FaHeart,
  FaHeadset,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaFacebook,
  FaBars,
  FaSearch,
  FaChevronCircleRight,
  FaChevronCircleLeft,

} from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { FaGear } from "react-icons/fa6";

// ------------

function Home() {
  const navigate = useNavigate();

  // دیتایhome

  const [HomeData, setHomeData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // متغیر های پاپ اپ join battle

  const [selectedBattleId, setSelectedBattleId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [, setSelectedOption] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);

  // متغیر های پاپ اپ members

  const [isMembersPopupOpen, setIsMembersPopupOpen] = useState(false); // برای پاپ‌آپ Members

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://redjayco.com/api/home",
        headers: {},
      };

      try {
        const response = await axios.request(config);
        if (
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data.battles)
        ) {
          setHomeData(response.data.data.battles);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);
  // --------------------

  //  پاپ آپ

  // هندل برای Join Battle
  const handleJoinBattleClick = (battleId) => {
    setSelectedBattleId(battleId);
    setIsPopupOpen(true);
    setStep(1); // بازگشت به مرحله اول هر زمان که پاپ‌آپ باز می‌شود
    console.log("Join battle ID:", battleId);
  };

  // هندل جدید برای Members
  const handleMemberClick = (battleId) => {
    setSelectedBattleId(battleId);
    setIsMembersPopupOpen(true);
    console.log("Selected battle ID for Members:", battleId);
  };

  // تابع برای انتخاب گزینه
  const handleOptionSelect = (option) => {
    setSelectedOption(option); // انتخاب کاربر (agree یا against) را ذخیره می‌کند
    joinBattle(option); // فراخوانی تابع joinBattle با گزینه انتخاب شده
    setStep(2); // رفتن به مرحله بعدی (در صورت نیاز)
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedBattleId(null);
    setWalletAddress(null);
  };

  const closeMembersPopup = () => {
    setIsMembersPopupOpen(false);
    setSelectedBattleId(null);
  };

  const getBattleById = (id) => {
    return HomeData.find((battle) => battle.id === id);
  };

  const selectedBattle = getBattleById(selectedBattleId);

  // --------------------------

  const [selectedCategory, setSelectedCategory] = useState("All games");

  // const categories = [
  //   "All games",
  //   "Shooter",
  //   "Online",
  //   "Offline",
  //   "FIFA",
  //   "Boardgames",
  //   "Card games",
  //   "TV games",
  //   "Betting",
  // ];

  const categoryBoxes = [
    {
      title: "FIFA",
      date: "June 21",
      player1: {
        name: "Player name",
        img: "https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg",
        bet: "$150",
      },
      player2: {
        name: "Player name",
        img: "https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg",
        bet: "$150",
      },
      winText: "Player 1 wins",
      winAmount: "$300",
      details:
        "Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when",
    },
    {
      title: "Shooter",
      date: "June 21",
      player1: {
        name: "Player name",
        img: "https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg",
        bet: "$150",
      },
      player2: {
        name: "Player name",
        img: "https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg",
        bet: "$150",
      },
      winText: "Player 1 wins",
      winAmount: "$300",
      details:
        "Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when",
    },
    {
      title: "Offline",
      date: "June 21",
      player1: {
        name: "Player name",
        img: "https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg",
        bet: "$150",
      },
      player2: {
        name: "Player name",
        img: "https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg",
        bet: "$150",
      },
      winText: "Player 1 wins",
      winAmount: "$300",
      details:
        "Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when",
    },
    {
      title: "Offline",
      date: "June 21",
      player1: {
        name: "Player name",
        img: "https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg",
        bet: "$150",
      },
      player2: {
        name: "Player name",
        img: "https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg",
        bet: "$150",
      },
      winText: "Player 1 wins",
      winAmount: "$300",
      details:
        "Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when",
    },
    {
      title: "Offline",
      date: "June 21",
      player1: {
        name: "Player name",
        img: "https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg",
        bet: "$150",
      },
      player2: {
        name: "Player name",
        img: "https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg",
        bet: "$150",
      },
      winText: "Player 1 wins",
      winAmount: "$300",
      details:
        "Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when",
    },
    // Add more category boxes if needed
  ];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredCategoryBoxes =
    selectedCategory === "All games"
      ? categoryBoxes
      : categoryBoxes.filter((box) => box.title === selectedCategory);

  const players = [
    {
      name: "Player name",
      wins: 31,
      amount: "$2,543",
      participatedBattles: 53,
      createdBattles: 23,
      img: "./assets/images/players-profile-img.jfif",
    },
    // Add more player objects if needed
  ];

  //  اسکرول لایو

  const sliderRef = useRef(null);
  const sliderRef2 = useRef(null);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  // اسکرول باز شده
  const scrollLeft2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  // join battle

  const joinBattle = (type) => {
    const accessToken = window.sessionStorage.getItem("access_token"); // گرفتن توکن از sessionStorage

    // بررسی وجود توکن
    if (!accessToken) {
      toast.error("Please login to your account.", {
        onClose: () => {
          navigate("/login"); // هدایت به صفحه لاگین پس از نمایش پیام
        },
      });
      return;
    }

    const data = JSON.stringify({
      battle_id: selectedBattleId,
      type: type,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://redjayco.com/api/join-battle",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // استفاده از توکن ذخیره‌شده
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setWalletAddress(response.data.data.wallet_address);
          // نمایش پیام موفقیت
          toast.success("You joined successfully!", {
            // در صورت نیاز به ری‌لود صفحه، می‌توانید window.location.reload() را دوباره فعال کنید
          });
        } else {
          // نمایش پیام خطا از سرور
          toast.error(
            response.data.message || "An error occurred while joining."
          );
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error.response?.data?.message ||
            "An error occurred. Please try again."
        );
      });
  };
  // هندل رفتن به ساخت بتل

  const handleCreateBattleClick = (e) => {
    e.preventDefault(); // جلوگیری از رفتار پیش‌فرض لینک

    const accessToken = window.sessionStorage.getItem("access_token");

    if (accessToken) {
      navigate("/create-battle");
    } else {
      toast.error("Please login your account", {
        autoClose: 3000, // زمان بسته شدن توست
        onClose: () => navigate("/login"), // هدایت به صفحه لاگین پس از بسته شدن توست
      });
    }
  };

  // دریافت کتگوری

  const [gameCategory, setGameCategory] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://redjayco.com/api/list-categories',
        headers: {}
      };

      try {
        const response = await axios.request(config);
        // دسترسی به آرایه categories در داخل data
        if (response.data && response.data.data && Array.isArray(response.data.data.categories)) {
          setGameCategory(response.data.data.categories);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []); // فقط یک بار هنگام بارگذاری کامپوننت اجرا شود


  // لیست مام ده

  // if (loading) {
  //   return (
  //     <div className="loading-container">
  //       <div className="loading-spinner"></div>
  //     </div>
  //   );
  // }

  return (
    <main className='main u-flex-box'>
      <input
        type='checkbox'
        id='aside-check'
        className='aside-check-input'
        hidden
      />
      <aside className='aside'>
        <label
          htmlFor='aside-check'
          className='aside-close-label u-flex-box u-align-items-center u-justify-content-center'
        >
          <IoMdCloseCircle className='fa-solid fa-x' />
        </label>
        <div className='aside-content'>
          <a
            href='/'
            className='aside-title-wrapper u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-super-small'
          >
            <h2 className='h2-title aside-title white-color'>Pedram Mokarmi</h2>
            <button className='gide-btn'>
              <FaChevronCircleRight className='fa-solid fa-chevron-right' />
            </button>
          </a>
          <a
            href='/'
            className='btn btn-primary-color notifactions-btn u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-super-small'
          >
            <FaBell className='fa-solid fa-bell' />
            Notifications
            <span className='notif-number u-flex-box u-align-items-center u-justify-content-center'>
              28
            </span>
          </a>
          <div className='aside-btn-wrapper u-flex-box u-align-items-center u-margin-bottom-super-small'>
            <a href='/' className='btn special-btn'>
              GAMES
            </a>
            <a href='/' className='btn special-btn special-btn-2'>
              GAMES
            </a>
          </div>
          <span className='aside-line u-margin-bottom-super-small'></span>
          <ul className='aside-list u-flex-box u-margin-bottom-super-small'>
            <li className='aside-item'>
              <a
                href='/'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaGamepad className='fa-solid fa-gamepad' />
                All games
              </a>
            </li>
            <li className='aside-item'>
              <a
                href='/create-battle'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaGamepad className='fa-solid fa-gamepad' />
                Create battle
              </a>
            </li>
            <li className='aside-item'>
              <a
                href='invite-friends'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaGamepad className='fa-solid fa-gamepad' />
                Invite Friends
              </a>
            </li>
            <li className='aside-item'>
              <a
                href='/profile-history'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaClock className='fa-solid fa-clock-rotate-left' />
                History
              </a>
            </li>
            <li className='aside-item'>
              <a
                href='/profile'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaUser className='fa-solid fa-user' />
                Profile
              </a>
            </li>
            <li className='aside-item'>
              <a
                href='/profile-favorite'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaHeart className='fa-solid fa-heart' />
                Favorite
              </a>
            </li>
            <li className='aside-item'>
              <a
                href='/setting'
                className='aside-link u-flex-box u-align-items-center'
              >
                <FaGear className='fa-solid fa-gear' />
                Setting
              </a>
            </li>
          </ul>
          <span className='aside-line u-margin-bottom-super-small'></span>
          <ul className='aside-mini-list'>
            <li className='aside-mini-item'>
              <a href='/activities' className='aside-mini-link'>
                Activities
              </a>
            </li>
            <li className='aside-mini-item'>
              <a href='/rank' className='aside-mini-link'>
                Rank system
              </a>
            </li>
            <li className='aside-mini-item'>
              <a href='/bonus' className='aside-mini-link'>
                Bonus
              </a>
            </li>
            <li className='aside-mini-item'>
              <a href='/learn' className='aside-mini-link'>
                Learn
              </a>
            </li>
          </ul>
          <div className='aside-bottom-box'>
            <a
              href='/support'
              className='btn notifactions-btn btn-primary-color u-margin-bottom-super-small'
            >
              <FaHeadset className='fa-solid fa-headset' />
              Live Support
            </a>
            <div className='aside-link-wrappper u-flex-box u-align-items-center u-justify-content-center'>
              <a href='www.youtube.com' className='social-link'>
                <FaYoutube className='fa-brands fa-youtube' />
              </a>
              <a href='www.twitter.com' className='social-link'>
                <FaTwitter className='fa-brands fa-twitter' />
              </a>
              <a href='www.instagram.com' className='social-link'>
                <FaInstagram className='fa-brands fa-instagram' />
              </a>
              <a href='wwww.facebook.com' className='social-link'>
                <FaFacebook className='fa-brands fa-facebook' />
              </a>
            </div>
          </div>
        </div>
      </aside>
      {/* <!--content---> */}
      <section className='page-content'>
        {/* <!--========header======--> */}
        <section className='header u-margin-bottom-big u-padding-2rem'>
          <div className='header-top-wrapper u-flex-box u-align-items-center u-justify-content-space-between u-margin-bottom-medium'>
            <div className='header-right-wrapper u-flex-box u-align-items-center'>
              <label htmlFor='aside-check' className='aside-open-label'>
                <FaBars className='fa-solid fa-bars' />
              </label>
              <form action='#' className='search-box'>
                <input
                  type='text'
                  className='search-input'
                  placeholder='Search battles'
                />
                <FaSearch className='fa-solid fa-search making-center-position' />
              </form>
            </div>
            <div className='header-top-btns-wrapper u-flex-box u-align-items-center'>
              <a
                href='/register'
                className='btn btn-primary-color header-top-btn'
              >
                Register
              </a>
              <a
                href='/login'
                className='btn btn-secondary-color header-top-btn'
              >
                Login
              </a>
            </div>
          </div>
          <div className='header-banner-v2'>
            <div className='banner-v2-text-box-content making-center-position'>
              <h1 className='primary-title white-color u-margin-bottom-medium'>
                Lorem Ipsum, sometimes referred to as lipsum is the placeholder
                text
              </h1>
              <a
                href='/create-battle'
                className='create-battle-btn btn btn-primary-color'
                onClick={handleCreateBattleClick}
              >
                Create battle
              </a>
            </div>
          </div>
        </section>
        {/* <!--=======main========--> */}
        <section className='content-main u-padding-2rem'>
          {/* <!--========live-game-section=====--> */}
          <section className='live-game-section u-margin-bottom-big'>
            {/* <!--title-wrapper--> */}
            <div className='live-game-title-wrapper u-flex-box u-align-items-center u-justify-content-space-between u-margin-bottom-small'>
              <h3 className='h4-title white-color'>Live Games</h3>
              <div className='live-game-top-btns-wrapper u-flex-box u-align-items-center'>
                <a
                  href='/'
                  className='view-all-btn btn-secondary-color u-flex-box u-align-items-center u-justify-content-center'
                >
                  View all
                  <span className='notif-number-view u-flex-box u-align-items-center u-justify-content-center'>
                    10
                  </span>
                  <FaChevronCircleRight className='fa-solid fa-chevron-right' />
                </a>
                <div className='gide-btns-wrapper u-flex-box u-align-items-center'>
                  <button
                    className='gide-btn main-gide-btn btn-secondary-color'
                    onClick={scrollLeft2}
                  >
                    <FaChevronCircleLeft className='fa-solid fa-chevron-left' />
                  </button>
                  <button
                    className='gide-btn main-gide-btn btn-secondary-color btn-active'
                    onClick={scrollRight2}
                  >
                    <FaChevronCircleRight className='fa-solid fa-chevron-right' />
                  </button>
                </div>
              </div>
            </div>
            {/* <!--slider-wrapper--> */}
            <div className='slider-wrapper slider-x' ref={sliderRef2}>
              <div className='live-box-slider u-flex-box u-align-items-center'>
                {HomeData.map((battle, index) => (
                  <div className='live-box' key={index}>
                    <div className='live-box-content u-margin-bottom-small'>
                      <div className='live-box-top-wrapper u-flex-box u-align-items-center u-justify-content-space-between'>
                        <p className='box-title-text'>{battle.title}</p>
                        <p className='box-title-text'>{battle.category}</p>
                      </div>
                      <p className='box-title-text box-live-text u-margin-bottom-super-small'>
                        Live
                      </p>
                      <div className='players-wrapper u-flex-box u-align-items-center u-justify-content-center'>
                        <div className='player-box u-flex-box u-align-items-center u-justify-content-center'>
                          <p className='player-group-text text-v2 white-color'>
                            Group A
                          </p>
                          <div className='player-profile'>
                            <img
                              src='https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg'
                              alt='img'
                              className='u-img-style player-profile-img'
                            />
                          </div>
                          <p className='player-name-text text-v2 white-color'>
                            <span className='owner-text'>Owner</span>
                            {battle.creator}
                          </p>
                          <div className='player-pop-up-wrapper'>
                            <p
                              className='live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center'
                              onClick={() => handleMemberClick(battle.id)}
                            >
                              Members
                              <FaChevronCircleRight className='fa-solid fa-chevron-down' />
                            </p>
                          </div>
                        </div>
                        <p className='vs-text vs-text-live-box'>VS</p>
                        <div className='player-box u-flex-box u-align-items-center u-justify-content-center'>
                          <p className='player-group-text text-v2 white-color'>
                            Group B
                          </p>
                          <div className='player-profile'>
                            <img
                              src='https://redjayco.com/uploads/71cf602bfe031f7dd67b467141f24dd2d91d061f.jpg'
                              alt='img'
                              className='u-img-style player-profile-img'
                            />
                          </div>
                          <p className='player-name-text text-v2 white-color'>
                            <span className='owner-text'>&nbsp;</span>
                            Player name
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='live-box-bottom-wrapper u-flex-box'>
                      <div className='win-condition-box'>
                        <p className='live-bottom-box-title u-margin-bottom-super-small'>
                          <i className='fa-solid fa-circle-exclamation'></i>
                          Win conditions
                        </p>

                        <p className='live-bottom-box-text white-color'>
                          {battle.description}
                        </p>
                      </div>
                      <div className='expire-box'>
                        <p className='live-bottom-box-title u-margin-bottom-super-small'>
                          Expire time
                        </p>
                        <div className='expire-content u-flex-box u-align-items-center u-justify-content-center'>
                          <p className='live-bottom-box-text-bold white-color u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-super-small'>
                            {battle.expire_at_register}
                          </p>
                          <button
                            className='live-join-btn'
                            onClick={() => handleJoinBattleClick(battle.id)}
                          >
                            Join battle
                          </button>
                        </div>
                      </div>
                      <span className='live-box-line making-center-position u-flex-box u-align-items-center u-justify-content-center'></span>
                    </div>
                  </div>
                ))}

                {/* پاپ‌آپ Join Battle */}
                {isPopupOpen && selectedBattle && (
                  <div className='popup-overlay'>
                    <div className='popup-content'>
                      {step === 1 && (
                        <div>
                          <h3>Select Option</h3>
                          <button onClick={() => handleOptionSelect('agree')}>
                            Agree
                          </button>
                          <button onClick={() => handleOptionSelect('against')}>
                            Against
                          </button>
                        </div>
                      )}
                      {step === 2 && (
                        <div>
                          <h4>Wallet Adress</h4>
                          <p>{walletAddress}</p>
                        </div>
                      )}
                      <button onClick={closePopup}>Close</button>
                    </div>
                  </div>
                )}

                {/* پاپ‌آپ Members */}
                {isMembersPopupOpen && selectedBattle && (
                  <div className='popup-overlay'>
                    <div className='popup-content'>
                      <h3>Agree Members</h3>
                      <div>
                        {selectedBattle.user_agree.map((user, i) => (
                          <p key={i}>{user}</p>
                        ))}
                      </div>
                      <h3>Against Members</h3>
                      <div>
                        {selectedBattle.user_against.map((user, i) => (
                          <p key={i}>{user}</p>
                        ))}
                      </div>
                      <button onClick={closeMembersPopup}>Close</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <!--==========games-category-section=============--> */}
            <section className='games-category-section u-margin-bottom-big'>
              <div className='category-lable-wrapper u-flex-box u-align-items-center u-justify-content-space-between u-margin-bottom-medium'>
                {gameCategory.map((category, index) => (
                  <React.Fragment key={index}>
                    <input
                      type='radio'
                      name='category-radio'
                      id={`category-radio-${index + 1}`}
                      hidden
                      className='category-radio-input'
                      defaultChecked={index === 0}
                      onChange={() => handleCategoryChange(category)}
                    />
                    <label
                      htmlFor={`category-radio-${index + 1}`}
                      className='category-radio-label'
                    >
                      {category.title} {/* نمایش عنوان دسته */}
                    </label>
                  </React.Fragment>
                ))}
              </div>
              <div
                className='slider-wrapper slider-x u-margin-bottom-small'
                ref={sliderRef}
              >
                <div className='category-box-slider u-flex-box u-align-items-center'>
                  {filteredCategoryBoxes.map((box, index) => (
                    <a href='/' className='category-box-link' key={index}>
                      <div className='category-box'>
                        <img
                          src={union}
                          alt='img'
                          className='category-box-background-img u-img-style u-img-style-contain'
                        />
                        <div className='category-box-content'>
                          <h4 className='category-box-title u-flex-box u-align-items-center u-justify-content-center'>
                            {box.title}
                          </h4>
                          <p className='category-date-text text-v2 white-color u-margin-bottom-medium'>
                            {box.date}
                          </p>
                          <div className='players-wrapper u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-medium'>
                            <div className='player-box u-flex-box u-align-items-center u-justify-content-center'>
                              <div className='player-profile player-profile-2'>
                                <img
                                  src={box.player1.img}
                                  alt='img'
                                  className='u-img-style player-profile-img'
                                />
                              </div>
                              <p className='player-name-text text-v2 white-color'>
                                {box.player1.name}
                              </p>
                              <p className='category-box-bet-number u-flex-box u-align-items-center'>
                                {box.player1.bet}
                                <img
                                  src='https://redjayco.com/uploads/16570e54100172d58d86feb506197560d5816b6d.png'
                                  alt='img'
                                  className='chip-img'
                                />
                              </p>
                            </div>
                            <p className='vs-text vs-text-live-box'>VS</p>
                            <div className='player-box u-flex-box u-align-items-center u-justify-content-center'>
                              <div className='player-profile player-profile-2'>
                                <img
                                  src={box.player2.img}
                                  alt='img'
                                  className='u-img-style player-profile-img'
                                />
                              </div>
                              <p className='player-name-text text-v2 white-color'>
                                {box.player2.name}
                              </p>
                              <p className='category-box-bet-number u-flex-box u-align-items-center'>
                                {box.player2.bet}
                                <img
                                  src='https://redjayco.com/uploads/16570e54100172d58d86feb506197560d5816b6d.png'
                                  alt='img'
                                  className='chip-img'
                                />
                              </p>
                            </div>
                          </div>
                          <div className='category-bottom-box u-margin-top-small'>
                            <div className='category-bottom-box-title u-flex-box u-align-items-center u-justify-content-center making-center-position'>
                              <p className='h5-title black-color'>
                                {box.winText}
                                <span className='red-color'>
                                  {box.winAmount}
                                </span>
                              </p>
                            </div>
                            <p className='category-deatails-text text-v2 white-color u-margin-bottom-small'>
                              <i className='fa-solid fa-circle-question'></i>
                              Deatails
                            </p>
                            <p className='text-v2 white-color category-deatails-text-p'>
                              {box.details}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <div className='live-game-title-wrapper-v2 u-flex-box u-align-items-center u-justify-content-space-between u-margin-bottom-small'>
                <div className='live-game-top-btns-wrapper u-flex-box u-align-items-center'>
                  <a
                    href='/'
                    className='view-all-btn btn-secondary-color u-flex-box u-align-items-center u-justify-content-center'
                  >
                    View all
                    <span className='notif-number-view u-flex-box u-align-items-center u-justify-content-center'>
                      29
                    </span>
                    <i className='fa-solid fa-chevron-right'></i>
                  </a>
                  <div className='gide-btns-wrapper u-flex-box u-align-items-center'>
                    <button
                      className='gide-btn main-gide-btn btn-secondary-color'
                      onClick={scrollLeft}
                    >
                      <FaChevronCircleLeft className='fa-solid fa-chevron-left' />
                    </button>
                    <button
                      className='gide-btn main-gide-btn btn-secondary-color btn-active'
                      onClick={scrollRight}
                    >
                      <FaChevronCircleRight className='fa-solid fa-chevron-right' />
                    </button>
                  </div>
                </div>
              </div>
            </section>
            {/* <!--============banner-section===================--> */}
            <section className='banner-section u-margin-bottom-big'>
              <span className='banner-overlay'></span>
              <div className='banner-img-wrapper making-center-position'>
                <img
                  src='https://redjayco.com/uploads/99edd028d060b993191894985bc9f80d412f68aa.jpg'
                  alt='img'
                  className='u-img-style banner-img'
                />
              </div>
              <div className='banner-content making-center-position'>
                <p className='h3-title white-color banner-title u-margin-bottom-small'>
                  Lorem Ipsum, sometimes referred to as 'lipsum', is the
                  placeholder text used in design when creating.
                </p>
                <div className='banner-btn-wrapper u-flex-box u-align-items-center'>
                  <button className='btn btn-primary-color banner-btn'>
                    <i className='fa-solid fa-arrow-up-from-bracket'></i>
                    Share Link
                  </button>
                </div>
              </div>
              <p className='banner-background-text making-center-position'>
                <span className='banner-text-span'>INVITE</span>
                <span className='banner-text-span-2'>FRIENDS</span>
              </p>
            </section>
            {/* <!--============banner-section===================--> */}
            <section className='banner-section u-margin-bottom-big'>
              <span className='banner-overlay'></span>
              <div className='banner-img-wrapper making-center-position banner-img-v2'>
                <img
                  src='https://redjayco.com/uploads/e9b6d9da6be5d8447e3898ff964e57c5e9ec8b00.jpg'
                  alt='img'
                  className='u-img-style banner-img'
                />
              </div>
              <div className='banner-content banner-content-v2 making-center-position'>
                <p className='banner-content-title-text u-margin-bottom-small'>
                  If you need tokens for playing
                </p>
                <p className='h3-title white-color banner-title u-margin-bottom-small'>
                  Lorem Ipsum, sometimes referred to as 'lipsum', is the
                  placeholder text used in design when creating.
                </p>
                <div className='banner-btn-wrapper-v2 u-flex-box u-align-items-center'>
                  <button className='btn btn-primary-color banner-btn'>
                    <i className='fa-solid fa-arrow-up-from-bracket'></i>
                    Share Link
                  </button>
                </div>
              </div>
            </section>
            {/* <!--============player-profiles-section===================--> */}
            <section className='players-profile-section u-margin-bottom-super-big'>
              <div className='live-game-title-wrapper u-flex-box u-align-items-center u-justify-content-space-between u-margin-bottom-small'>
                <h3 className='h4-title white-color'>Player Profiles</h3>
                <div className='live-game-top-btns-wrapper u-flex-box u-align-items-center'>
                  <a
                    href='/'
                    className='view-all-btn btn-secondary-color u-flex-box u-align-items-center u-justify-content-center'
                  >
                    View all
                    <i className='fa-solid fa-chevron-right'></i>
                  </a>
                  <input
                    type='checkbox'
                    className='sort-btn-input'
                    id='sort-btn-input-1'
                    hidden
                  />
                  <label
                    htmlFor='sort-btn-input-1'
                    className='sort-btn-label u-flex-box u-align-items-center u-justify-content-center'
                  >
                    <p className='sort-btn-text u-flex-box u-align-items-center u-justify-content-space-between'>
                      Sort by
                      <i className='fa-solid fa-chevron-down'></i>
                    </p>
                    <ul className='sort-drop-list'>
                      <li className='sort-drop-item'>Win Price</li>
                      <li className='sort-drop-item'>Game Played</li>
                      <li className='sort-drop-item'>Battle Created</li>
                    </ul>
                  </label>
                </div>
              </div>
              <div className='slider-wrapper slider-x u-margin-bottom-small'>
                <div className='players-profiles-main-wrapper u-flex-box u-align-items-center'>
                  {players.map((player, index) => (
                    <div
                      className='players-profile-box-wrpaper u-flex-box u-align-items-center'
                      key={index}
                    >
                      <a
                        href='/'
                        className='players-profile-box u-flex-box u-align-items-center'
                      >
                        <div className='players-profile-box-img'>
                          <img
                            src={player.img}
                            alt='img'
                            className='players-profile-img-content u-img-style'
                          />
                        </div>
                        <div className='players-profile-text-box players-profile-text-box-v1 u-flex-box'>
                          <h5 className='player-name-text-v2 text-v1 white-color'>
                            {player.name}
                          </h5>
                          <p className='win-text primary-color text-v3'>
                            {player.wins}{' '}
                            <span className='win-text-span text-v1'>wins</span>
                          </p>
                          <p className='players-profile-number-text primary-color text-v3'>
                            {player.amount}
                          </p>
                        </div>
                        <div className='players-profile-text-box players-profile-text-box-v2 u-flex-box u-justify-content-space-between'>
                          <h5 className='player-name-text-v2 text-v1 white-color'>
                            <span className='text-v3 primary-color'>
                              {player.participatedBattles}
                            </span>{' '}
                            Participated battles
                          </h5>
                          <h5 className='player-name-text-v2 text-v1 white-color'>
                            <span className='text-v3 primary-color'>
                              {player.createdBattles}
                            </span>{' '}
                            Created battles
                          </h5>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </section>
        </section>
        {/* <!--=========footer=======--> */}
        <section className='footer-section'>
          <ul className='footer-list u-flex-box'>
            <li className='footer-list-item footer-big-item'>
              <strong className='footer-logo-text h2-title aside-title primary-color u-margin-bottom-small'>
                Pedram Mokarmi
              </strong>
              <p className='footer-text white-color'>
                Lorem Ipsum, sometimes referred to as 'lipsum', is the
                placeholder text used in design when creating content. It helps
                designers plan out where the content will sit, without needing
                to wait for the content to be written
              </p>
            </li>
            <li className='footer-list-item'>
              <ul className='footer-link-list u-flex-box'>
                <li className='footer-link-item-title'>About</li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Live support
                  </a>
                </li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Help center
                  </a>
                </li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Lorem ipsum
                  </a>
                </li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Ipsum
                  </a>
                </li>
              </ul>
            </li>
            <li className='footer-list-item'>
              <ul className='footer-link-list u-flex-box'>
                <li className='footer-link-item-title'>Support</li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Terms of service
                  </a>
                </li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Privacy and policy
                  </a>
                </li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Awards
                  </a>
                </li>
                <li className='footer-link-item'>
                  <a href='/' className='footer-link'>
                    Rules
                  </a>
                </li>
              </ul>
            </li>
            <li className='footer-list-item footer-social-item'>
              <h4 className='footer-link-item-title u-margin-bottom-small'>
                Contact Us
              </h4>
              <a
                href='/'
                className='footer-email-link footer-link-item footer-link u-flex-box u-align-items-center u-margin-bottom-small'
              >
                <i className='fa-solid fa-envelope'></i>
                Lorem.ipsum@gmail.com
              </a>
              <h4 className='footer-link-item-title u-margin-bottom-small'>
                Follow Us
              </h4>
              <div className='footer-social-links-wrapper u-flex-box u-align-items-center'>
                <a href='/' className='footer-social-link'>
                  <i className='fa-brands fa-twitter'></i>
                </a>
                <a href='/' className='footer-social-link'>
                  <i className='fa-brands fa-instagram'></i>
                </a>
                <a href='/' className='footer-social-link'>
                  <i className='fa-brands fa-linkedin'></i>
                </a>
                <a href='/' className='footer-social-link'>
                  <i className='fa-brands fa-telegram'></i>
                </a>
                <a href='/' className='footer-social-link'>
                  <i className='fa-brands fa-youtube'></i>
                </a>
              </div>
            </li>
          </ul>
        </section>
      </section>

      <ToastContainer toastClassName='toast-right' />
    </main>
  )
}

export default Home;
