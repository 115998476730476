import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";

// icon
import { FaEdit, FaGamepad,FaHistory  } from "react-icons/fa";
import { CiHeart, CiFlag1 } from "react-icons/ci";

function Profile() {
  // دریافت اطلاعات کاربر

  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const accessToken = window.sessionStorage.getItem("access_token");
      setLoading(true);
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://redjayco.com/api/show-profile",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        const response = await axios.request(config);
        setUserProfile(response.data.data.show_profile);
      } catch (error) {
        console.error("Error fetching the profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  // اجرای لودینگ

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <body>
      {/* <!--============ HEADER ===========--> */}
      <header className="profile-header">
        {/* <!--banner---> */}
        <div className="profile-header-banner">
          {/* <!--top-content--> */}
          <div className="profile-header-up-content u-flex-box u-align-items-center">
            {/* <!--text--> */}
            <p className="h4-title white-color">
              {userProfile.participation_battle_count} Participated battle
            </p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">
              {userProfile.create_battle_count} Created battle
            </p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color"> 20 Battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">
              ${userProfile.count_win} Wins
            </p>
          </div>
          {/* <!--bottom-content--> */}
          {/* <!--edite-button--> */}
          <button className="edite-btn">
            {/* <!--icon--> */}
            <FaEdit className="fa-solid fa-pen-to-square" />
          </button>
        </div>
        {/* <!--info--> */}
        <div className="profile-header-info u-flex-box u-align-items-center u-justify-content-space-between">
          {/* <!--profile-box--> */}
          <div className="profile-wrapper">
            {/* <!--img--> */}
            <div className="profile-img u-margin-bottom-small">
              <img
                src="https://redjayco.com/uploads/c00616bf8d94c8d9741d7999f7a98f1a767bc74c.jpg"
                alt="img"
                className="profile-img-content u-img-style"
              />
            </div>
            {/* <!--player-name--> */}
            <h3 className="player-name h3-title white-color">
              {userProfile.first_name} {userProfile.last_name}
            </h3>
            {/* <!--text--> */}
            <p className="date-text text-v2 white-color">Created in 21 june</p>
          </div>
          {/* <!--btns-wrapper--> */}
          <div className="info-box-btns-wrapper u-flex-box u-align-items-center">
            {/* <!--btn--> */}
            <button className="profile-btn btn-primary-color">
              <CiHeart className="fa-solid fa-heart" />
            </button>
            {/* <!--btn--> */}
            <button className="profile-btn btn-primary-color">
              <i className="fa-solid fa-flag"></i>
              <CiFlag1 className="fa-solid fa-flag" />
            </button>
          </div>
        </div>
      </header>
      {/* <!--=========main============--> */}
      <main className="profile-main">
        {/* <!--========profile-main-wrapper========--> */}
        <div className="profile-main-wrapper u-flex-box">
          {/* <!--aside--> */}
          <aside className="profile-aside">
            {/* <!--list--> */}
            <ul className="profile-option-list">
              {/* <!--item--> */}
              <li className="profile-option-item">
                <a
                  href="/"
                  className="profile-option-link u-flex-box u-align-items-center"
                >
                  {/* <!--icon--> */}
                  <FaGamepad className="fa-solid fa-gamepad" />
                  Invite friends
                </a>
              </li>
              {/* <!--item--> */}
              <li className="profile-option-item">
                <a
                  href="/"
                  className="profile-option-link u-flex-box u-align-items-center"
                >
                  {/* <!--icon--> */}
                  <FaHistory className="fa-solid fa-clock-rotate-left" />
                  History
                </a>
              </li>
              {/* <!--item--> */}
              <li className="profile-option-item">
                <a
                  href="/"
                  className="profile-option-link u-flex-box u-align-items-center"
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-heart"></i>
                  Favorites
                </a>
              </li>
            </ul>
          </aside>
          {/* <!--content--> */}
          <div className="profile-main-content">
            {/* <!--mobile-list--> */}
            <ul className="profile-mobile-list u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-medium">
              <li className="profile-mobile-item">
                <a
                  href="/"
                  className="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-gamepad"></i>
                  Invite friends
                </a>
              </li>
              <li className="profile-mobile-item">
                <a
                  href="/"
                  className="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-heart"></i>
                  Favorites
                </a>
              </li>
              <li className="profile-mobile-item">
                <a
                  href="/"
                  className="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i className="fa-solid fa-clock-rotate-left"></i>
                  History
                </a>
              </li>
            </ul>

            {/* <!--title--> */}
            <h3 className="h4-title white-color u-margin-bottom-small">
              Live battles
            </h3>

            {/* <!--profile-boxes-wrapper--> */}
            <div className="profile-boxes-wrapper u-flex-box u-align-items-center u-justify-content-center">
              {/* <!--box--> */}
              <div className="live-box">
                {/* <!--live-box-content--> */}
                <div class="live-box-content u-margin-bottom-small">
                  {/* <!--top-wrapper--> */}
                  <div class="live-box-top-wrapper u-flex-box u-align-items-center u-justify-content-space-between">
                    {/* <!--title-text--> */}
                    <p class="box-title-text">Football prediction</p>
                    {/* <!--title-text--> */}
                    <p class="box-title-text">Title ( german & france )</p>
                  </div>
                  {/* <!--live-text--> */}
                  <p class="box-title-text box-live-text u-margin-bottom-super-small">
                    Live
                  </p>
                  {/* <!--players-wrapper--> */}
                  <div class="players-wrapper u-flex-box u-align-items-center u-justify-content-center">
                    {/* <!--player-box--> */}
                    <div class="player-box u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--player-group--> */}
                      <p class="player-group-text text-v2 white-color">
                        Group A
                      </p>
                      {/* <!--player-profile--> */}
                      <div class="player-profile">
                        <img
                          src="./assets/images/perdiction-profile-img.jfif"
                          alt="img"
                          class="u-img-style player-profile-img"
                        />
                      </div>
                      {/* <!--player-group--> */}
                      <p class="player-name-text text-v2 white-color">
                        <span class="owner-text">Owner</span>
                        Player name
                      </p>
                      {/* <!--player-pop-up--> */}
                      <div class="player-pop-up-wrapper">
                        {/* <!--text--> */}
                        <p class="live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center">
                          Members
                          {/* <!--icon--> */}
                          <i class="fa-solid fa-chevron-down"></i>
                        </p>
                      </div>
                    </div>
                    {/* <!--text--> */}
                    <p class="vs-text vs-text-live-box">VS</p>
                    {/* <!--player-box--> */}
                    <div class="player-box u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--player-group--> */}
                      <p class="player-group-text text-v2 white-color">
                        Group A
                      </p>
                      {/* <!--player-profile--> */}
                      <div class="player-profile">
                        <img
                          src="./assets/images/player-2.jfif"
                          alt="img"
                          class="u-img-style player-profile-img"
                        />
                      </div>
                      {/* <!--player-group--> */}
                      <p class="player-name-text text-v2 white-color">
                        <span class="owner-text">&nbsp;</span>
                        Player name
                      </p>
                      {/* <!--player-pop-up--> */}
                      <div class="player-pop-up-wrapper">
                        {/* <!--text--> */}
                        <p class="live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center">
                          Members
                          {/* <!--icon--> */}
                          <i class="fa-solid fa-chevron-down"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--bottom-wrapper--> */}
                <div class="live-box-bottom-wrapper u-flex-box">
                  {/* <!--right-box--> */}
                  <div class="win-condition-box">
                    <p class="live-bottom-box-title u-margin-bottom-super-small">
                      {/* <!--icon--> */}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      Win conditions
                    </p>

                    <p class="live-bottom-box-text white-color">
                      Lorem Ipsum, sometimes referred to as 'lipsum', is the
                      placeholder text used in design when
                    </p>
                  </div>
                  {/* <!--left-bxo--> */}
                  <div class="expire-box">
                    <p class="live-bottom-box-title u-margin-bottom-super-small">
                      {/* <!--icon--> */}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      Expire time
                    </p>
                    <div class="expire-content u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--text--> */}
                      <p class="live-bottom-box-text-bold white-color u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-super-small">
                        June 21 / 20 : 32 : 56
                      </p>
                      {/* <!--btn--> */}
                      <button class="live-join-btn">Join battle</button>
                    </div>
                  </div>
                  {/* <!--line--> */}
                  <span class="live-box-line making-center-position u-flex-box u-align-items-center u-justify-content-center"></span>
                </div>
              </div>
              {/* ------------------------- */}
              <div className="live-box">
                {/* <!--live-box-content--> */}
                <div class="live-box-content u-margin-bottom-small">
                  {/* <!--top-wrapper--> */}
                  <div class="live-box-top-wrapper u-flex-box u-align-items-center u-justify-content-space-between">
                    {/* <!--title-text--> */}
                    <p class="box-title-text">Football prediction</p>
                    {/* <!--title-text--> */}
                    <p class="box-title-text">Title ( german & france )</p>
                  </div>
                  {/* <!--live-text--> */}
                  <p class="box-title-text box-live-text u-margin-bottom-super-small">
                    Live
                  </p>
                  {/* <!--players-wrapper--> */}
                  <div class="players-wrapper u-flex-box u-align-items-center u-justify-content-center">
                    {/* <!--player-box--> */}
                    <div class="player-box u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--player-group--> */}
                      <p class="player-group-text text-v2 white-color">
                        Group A
                      </p>
                      {/* <!--player-profile--> */}
                      <div class="player-profile">
                        <img
                          src="./assets/images/perdiction-profile-img.jfif"
                          alt="img"
                          class="u-img-style player-profile-img"
                        />
                      </div>
                      {/* <!--player-group--> */}
                      <p class="player-name-text text-v2 white-color">
                        <span class="owner-text">Owner</span>
                        Player name
                      </p>
                      {/* <!--player-pop-up--> */}
                      <div class="player-pop-up-wrapper">
                        {/* <!--text--> */}
                        <p class="live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center">
                          Members
                          {/* <!--icon--> */}
                          <i class="fa-solid fa-chevron-down"></i>
                        </p>
                      </div>
                    </div>
                    {/* <!--text--> */}
                    <p class="vs-text vs-text-live-box">VS</p>
                    {/* <!--player-box--> */}
                    <div class="player-box u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--player-group--> */}
                      <p class="player-group-text text-v2 white-color">
                        Group A
                      </p>
                      {/* <!--player-profile--> */}
                      <div class="player-profile">
                        <img
                          src="./assets/images/player-2.jfif"
                          alt="img"
                          class="u-img-style player-profile-img"
                        />
                      </div>
                      {/* <!--player-group--> */}
                      <p class="player-name-text text-v2 white-color">
                        <span class="owner-text">&nbsp;</span>
                        Player name
                      </p>
                      {/* <!--player-pop-up--> */}
                      <div class="player-pop-up-wrapper">
                        {/* <!--text--> */}
                        <p class="live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center">
                          Members
                          {/* <!--icon--> */}
                          <i class="fa-solid fa-chevron-down"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--bottom-wrapper--> */}
                <div class="live-box-bottom-wrapper u-flex-box">
                  {/* <!--right-box--> */}
                  <div class="win-condition-box">
                    <p class="live-bottom-box-title u-margin-bottom-super-small">
                      {/* <!--icon--> */}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      Win conditions
                    </p>

                    <p class="live-bottom-box-text white-color">
                      Lorem Ipsum, sometimes referred to as 'lipsum', is the
                      placeholder text used in design when
                    </p>
                  </div>
                  {/* <!--left-bxo--> */}
                  <div class="expire-box">
                    <p class="live-bottom-box-title u-margin-bottom-super-small">
                      {/* <!--icon--> */}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      Expire time
                    </p>
                    <div class="expire-content u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--text--> */}
                      <p class="live-bottom-box-text-bold white-color u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-super-small">
                        June 21 / 20 : 32 : 56
                      </p>
                      {/* <!--btn--> */}
                      <button class="live-join-btn">Join battle</button>
                    </div>
                  </div>
                  {/* <!--line--> */}
                  <span class="live-box-line making-center-position u-flex-box u-align-items-center u-justify-content-center"></span>
                </div>
              </div>
              {/* ------------------------- */}
              <div className="live-box">
                {/* <!--live-box-content--> */}
                <div class="live-box-content u-margin-bottom-small">
                  {/* <!--top-wrapper--> */}
                  <div class="live-box-top-wrapper u-flex-box u-align-items-center u-justify-content-space-between">
                    {/* <!--title-text--> */}
                    <p class="box-title-text">Football prediction</p>
                    {/* <!--title-text--> */}
                    <p class="box-title-text">Title ( german & france )</p>
                  </div>
                  {/* <!--live-text--> */}
                  <p class="box-title-text box-live-text u-margin-bottom-super-small">
                    Live
                  </p>
                  {/* <!--players-wrapper--> */}
                  <div class="players-wrapper u-flex-box u-align-items-center u-justify-content-center">
                    {/* <!--player-box--> */}
                    <div class="player-box u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--player-group--> */}
                      <p class="player-group-text text-v2 white-color">
                        Group A
                      </p>
                      {/* <!--player-profile--> */}
                      <div class="player-profile">
                        <img
                          src="./assets/images/perdiction-profile-img.jfif"
                          alt="img"
                          class="u-img-style player-profile-img"
                        />
                      </div>
                      {/* <!--player-group--> */}
                      <p class="player-name-text text-v2 white-color">
                        <span class="owner-text">Owner</span>
                        Player name
                      </p>
                      {/* <!--player-pop-up--> */}
                      <div class="player-pop-up-wrapper">
                        {/* <!--text--> */}
                        <p class="live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center">
                          Members
                          {/* <!--icon--> */}
                          <i class="fa-solid fa-chevron-down"></i>
                        </p>
                      </div>
                    </div>
                    {/* <!--text--> */}
                    <p class="vs-text vs-text-live-box">VS</p>
                    {/* <!--player-box--> */}
                    <div class="player-box u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--player-group--> */}
                      <p class="player-group-text text-v2 white-color">
                        Group A
                      </p>
                      {/* <!--player-profile--> */}
                      <div class="player-profile">
                        <img
                          src="./assets/images/player-2.jfif"
                          alt="img"
                          class="u-img-style player-profile-img"
                        />
                      </div>
                      {/* <!--player-group--> */}
                      <p class="player-name-text text-v2 white-color">
                        <span class="owner-text">&nbsp;</span>
                        Player name
                      </p>
                      {/* <!--player-pop-up--> */}
                      <div class="player-pop-up-wrapper">
                        {/* <!--text--> */}
                        <p class="live-box-text-v1 u-flex-box u-align-items-center u-justify-content-center">
                          Members
                          {/* <!--icon--> */}
                          <i class="fa-solid fa-chevron-down"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--bottom-wrapper--> */}
                <div class="live-box-bottom-wrapper u-flex-box">
                  {/* <!--right-box--> */}
                  <div class="win-condition-box">
                    <p class="live-bottom-box-title u-margin-bottom-super-small">
                      {/* <!--icon--> */}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      Win conditions
                    </p>

                    <p class="live-bottom-box-text white-color">
                      Lorem Ipsum, sometimes referred to as 'lipsum', is the
                      placeholder text used in design when
                    </p>
                  </div>
                  {/* <!--left-bxo--> */}
                  <div class="expire-box">
                    <p class="live-bottom-box-title u-margin-bottom-super-small">
                      {/* <!--icon--> */}
                      <i class="fa-solid fa-circle-exclamation"></i>
                      Expire time
                    </p>
                    <div class="expire-content u-flex-box u-align-items-center u-justify-content-center">
                      {/* <!--text--> */}
                      <p class="live-bottom-box-text-bold white-color u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-super-small">
                        June 21 / 20 : 32 : 56
                      </p>
                      {/* <!--btn--> */}
                      <button class="live-join-btn">Join battle</button>
                    </div>
                  </div>
                  {/* <!--line--> */}
                  <span class="live-box-line making-center-position u-flex-box u-align-items-center u-justify-content-center"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </body>
  );
}

export default Profile;
