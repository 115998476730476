import React from "react";
// import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";

// icon

import {FaRegEdit } from "react-icons/fa";
import { CiHeart,CiFlag1  } from "react-icons/ci";

function ProfileFavorite() {
  return (
    <body>
      {/* <!--============ HEADER ===========--> */}
      <header className="profile-header">
        {/* <!--banner---> */}
        <div className="profile-header-banner">
          {/* <!--top-content--> */}
          <div className="profile-header-up-content u-flex-box u-align-items-center">
            {/* <!--text--> */}
            <p className="h4-title white-color">51 Participated battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">62 Created battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">Battle</p>
            {/* <!--line--> */}
            <span className="profile-header-line"></span>
            {/* <!--text--> */}
            <p className="h4-title white-color">$2,500 Wins</p>
          </div>
          {/* <!--bottom-content--> */}
          {/* <!--edite-button--> */}
          <button className="edite-btn">
            {/* <!--icon--> */}

            <FaRegEdit className="fa-solid fa-pen-to-square" />
          </button>
        </div>
        {/* <!--info--> */}
        <div className="profile-header-info u-flex-box u-align-items-center u-justify-content-space-between">
          {/* <!--profile-box--> */}
          <div className="profile-wrapper">
            {/* <!--img--> */}
            <div className="profile-img u-margin-bottom-small">
              <img
                src="https://redjayco.com/uploads/c312c90130cef89b63c76ce9fed4d214187b03ce.jpg"
                alt="img"
                className="profile-img-content u-img-style"
              />
            </div>
            {/* <!--player-name--> */}
            <h3 class="player-name h3-title white-color">Player name</h3>
            {/* <!--text--> */}
            <p className="date-text text-v2 white-color">Created in 21 june</p>
          </div>
          {/* <!--btns-wrapper--> */}
          <div className="info-box-btns-wrapper u-flex-box u-align-items-center">
            {/* <!--btn--> */}
            <button className="profile-btn btn-primary-color">
              <CiHeart className="fa-solid fa-heart" />
            </button>
            {/* <!--btn--> */}
            <button className="profile-btn btn-primary-color">
              <i className="fa-solid fa-flag"></i>
              <CiFlag1 className="fa-solid fa-flag" />
            </button>
          </div>
        </div>
      </header>
      {/* <!--=========main============--> */}
      <main className="profile-main">
        {/* <!--========profile-main-wrapper========--> */}
        <div className="profile-main-wrapper u-flex-box">
          {/* <!--aside--> */}
          <aside class="profile-aside">
            {/* <!--list--> */}
            <ul class="profile-option-list">
              {/* <!--item--> */}
              <li class="profile-option-item">
                <a
                  href="/"
                  class="profile-option-link u-flex-box u-align-items-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-gamepad"></i>
                  Invite friends
                </a>
              </li>
              {/* <!--item--> */}
              <li class="profile-option-item">
                <a
                  href="/"
                  class="profile-option-link u-flex-box u-align-items-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-clock-rotate-left"></i>
                  History
                </a>
              </li>
              {/* <!--item--> */}
              <li class="profile-option-item ">
                <a
                  href="./profile-favorite.html"
                  class="profile-option-link u-flex-box u-align-items-center profile-option-link-active"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-heart"></i>
                  Favorites
                </a>
              </li>
            </ul>
          </aside>
          {/* <!--content--> */}
          <div className="profile-main-content">
            {/* <!--mobile-list--> */}
            <ul className="profile-mobile-list u-flex-box u-align-items-center u-justify-content-center u-margin-bottom-medium">
              <li class="profile-mobile-item">
                <a
                  href="/"
                  class="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-gamepad"></i>
                  Invite friends
                </a>
              </li>
              <li class="profile-mobile-item">
                <a
                  href="/"
                  class="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-heart"></i>
                  Favorites
                </a>
              </li>
              <li class="profile-mobile-item">
                <a
                  href="/"
                  class="profile-mobile-link u-flex-box u-align-items-center u-justify-content-center"
                >
                  {/* <!--icon--> */}
                  <i class="fa-solid fa-clock-rotate-left"></i>
                  History
                </a>
              </li>
            </ul>

            {/* <!--title--> */}
            <h3 class="h4-title white-color u-margin-bottom-small">
              Favorite profiles
            </h3>

            {/* <!--profile-boxes-wrapper--> */}
            <div className="profile-boxes-wrapper u-flex-box u-align-items-center u-justify-content-center">
              {/* <!--box--> */}
              {/* <!--profiel-box--> */}
              <a
                href="/"
                class="players-profile-box u-flex-box u-align-items-center"
              >
                {/* <!--img-box--> */}
                <div class="players-profile-box-img">
                  <img
                    src="./assets/images/players-profile-img.jfif"
                    alt="img"
                    class="players-profile-img-content u-img-style"
                  />
                </div>
                {/* <!--text-box--> */}
                <div class="players-profile-text-box players-profile-text-box-v1 u-flex-box">
                  {/* <!--player-name--> */}
                  <h5 class="player-name-text-v2 text-v1 white-color">
                    Player name
                  </h5>
                  {/* <!--text--> */}
                  <p class="win-text primary-color text-v3">
                    31 <span class="win-text-span text-v1">wins</span>
                  </p>
                  {/* <!--text--> */}
                  <p class="players-profile-number-text primary-color text-v3">
                    $2,543
                  </p>
                </div>
                {/* <!--text-box--> */}
                <div class="players-profile-text-box players-profile-text-box-v2  u-flex-box u-justify-content-space-between">
                  {/* <!--player-name--> */}
                  <h5 class="player-name-text-v2 text-v1 white-color">
                    <span class="text-v3 primary-color">53</span> Participated
                    battles
                  </h5>

                  {/* <!--player-name--> */}
                  <h5 class="player-name-text-v2 text-v1 white-color">
                    <span class="text-v3 primary-color">23</span> Created
                    battles
                  </h5>
                </div>
              </a>
              {/* <!--profiel-box--> */}
              <a
                href="/"
                class="players-profile-box u-flex-box u-align-items-center"
              >
                {/* <!--img-box--> */}
                <div class="players-profile-box-img">
                  <img
                    src="./assets/images/players-profile-img.jfif"
                    alt="img"
                    class="players-profile-img-content u-img-style"
                  />
                </div>
                {/* <!--text-box--> */}
                <div class="players-profile-text-box players-profile-text-box-v1 u-flex-box">
                  {/* <!--player-name--> */}
                  <h5 class="player-name-text-v2 text-v1 white-color">
                    Player name
                  </h5>
                  {/* <!--text--> */}
                  <p class="win-text primary-color text-v3">
                    31 <span class="win-text-span text-v1">wins</span>
                  </p>
                  {/* <!--text--> */}
                  <p class="players-profile-number-text primary-color text-v3">
                    $2,543
                  </p>
                </div>
                {/* <!--text-box--> */}
                <div class="players-profile-text-box players-profile-text-box-v2  u-flex-box u-justify-content-space-between">
                  {/* <!--player-name--> */}
                  <h5 class="player-name-text-v2 text-v1 white-color">
                    <span class="text-v3 primary-color">53</span> Participated
                    battles
                  </h5>

                  {/* <!--player-name--> */}
                  <h5 class="player-name-text-v2 text-v1 white-color">
                    <span class="text-v3 primary-color">23</span> Created
                    battles
                  </h5>
                </div>
              </a>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </body>
  );
}

export default ProfileFavorite;
