import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';

import Contact from './pages/Contact'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import CreateBattle from './pages/CreateBattle'
import BattlePerview from './pages/BattlePerview';
import Profile from './pages/Profile';
import ProfileFavorite from './pages/ProfileFavorite';
import ProfileHistory from './pages/ProfileHistory'
function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/create-battle' element={<CreateBattle />} />
          <Route path='/battle-perview' element={<BattlePerview />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/profile-favorite' element={<ProfileFavorite />} />
          <Route path='/profile-history' element={<ProfileHistory />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
