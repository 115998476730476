import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";
import { useNavigate } from 'react-router-dom';

// icon

import { FaApple, FaFacebookF, FaGoogle } from "react-icons/fa";

function Login() {
  // هندل لاگین

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleLogin = (e) => {
    e.preventDefault()

    let data = JSON.stringify({
      email: email,
      password: password,
    })

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://redjayco.com/api/login-user',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    }

    axios
      .request(config)
      .then((response) => {
        if (response.data.is_status && response.data.statusCode === 200) {
          const { access_token } = response.data.data
          window.sessionStorage.setItem('access_token', access_token)
          toast.success('Login successful!', {
            autoClose: 5000,
            onClose: () => navigate('/'),
          })
        } else {
          toast.error(response.data.message || 'Login failed!', {
            autoClose: 5000,
          })
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message, { autoClose: 5000 })
        } else {
          toast.error('An error occurred during login!', { autoClose: 5000 })
        }
        console.log(error)
      })
  }

  // برگشت به خانه

  const handleBackClick = () => {
    navigate('/')
  }


  // نمایش پسسورد

    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => {
      setShowPassword(!showPassword)
    }

  return (
    <main>
      {/*=========form-section==============*/}
      <section className='form-section'>
        {/*form-wrapper*/}
        <div className='form-wrapper making-center-position'>
          <button className='btn-back' onClick={handleBackClick}>
            Back
          </button>
          {/*form*/}
          <form
            action='#'
            className='form-v1 login-form making-center-position'
            onSubmit={handleLogin}
          >
            {/*title*/}
            <h1 className='h1-title-secondary-color form-v1-title u-margin-bottom-form'>
              Login
            </h1>
            {/*form-group*/}
            <div className='form-group-v1 u-margin-bottom-form'>
              {/*input*/}
              <input
                type='text'
                name='email'
                className='form-group-v1-input'
                id='form-group-v1-email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {/*label*/}
              <label
                htmlFor='form-group-v1-email'
                className='form-group-v1-label'
              >
                Email
              </label>
            </div>
            {/*form-group*/}
            <div className='form-group-v1 u-margin-bottom-form'>
              {/* input */}
              <input
                type={showPassword ? 'text' : 'password'}
                name='password'
                className='form-group-v1-input'
                id='form-group-v1-password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {/* label */}
              <label
                htmlFor='form-group-v1-password'
                className='form-group-v1-label'
              >
                Password
              </label>
              {/* show-password */}
              <button
                type='button'
                className='show-password-button'
                onClick={toggleShowPassword}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            {/*form-group*/}
            <div className='form-group-v1 u-margin-bottom-form u-flex-box u-align-items-center u-justify-content-space-between'>
              {/*remmeber-me-wrapper*/}
              <div className='remember-me-wrapper u-flex-box u-align-items-center'>
                {/*input*/}
                <input
                  type='checkbox'
                  className='remember-check-box'
                  id='remember-check-box'
                />
                {/*label*/}
                <label htmlFor='remember-check-box' className='form-text-v1'>
                  Remember me
                </label>
              </div>
              {/*link*/}
              <a href='/forget-password' className='form-v1-link form-text-v1'>
                Forgot password?
              </a>
            </div>
            {/*form-group*/}
            <div className='form-group-lines-wrapper u-margin-bottom-form grid-box grid-3-columns u-align-items-center'>
              {/*line*/}
              <span className='form-v1-line'></span>
              {/*text*/}
              <p className='form-text-v2 form-text-v2-bold'>Login with</p>
              {/*line*/}
              <span className='form-v1-line'></span>
            </div>
            {/*form-v1-social-wrapper*/}
            <div className='form-v1-social-wrapper u-margin-bottom-form u-flex-box u-align-items-center u-justify-content-center'>
              {/*link*/}
              <a
                href='www.apple.com'
                className='form-v1-social-link form-v1-social-link-apple'
              >
                {/*icon*/}
                <FaApple className='fa-brands fa-apple' />
              </a>
              {/*link*/}
              <a href='www.facebook.com' className='form-v1-social-link'>
                {/*icon*/}
                <FaFacebookF className='fa-brands fa-facebook-f' />
              </a>
              {/*link*/}
              <a href='wwww.google.com' className='form-v1-social-link'>
                {/*icon*/}
                <FaGoogle className='fa-brands fa-google' />
              </a>
            </div>
            {/*form-btn*/}
            <div className='form-group-v1 u-margin-bottom-form'>
              <button
                type='submit'
                className='form-btn-v1 u-flex-box u-align-items-center u-justify-content-center'
              >
                Login
              </button>
            </div>
            {/*form-group*/}
            <div className='form-group-v1 form-down-text-wrapper u-flex-box u-justify-content-center u-align-items-center'>
              {/*text*/}
              <p className='form-text-v2'>Don’t have an account?</p>
              {/*link*/}
              <a href='/register' className='form-v1-link-primary-color'>
                Register
              </a>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
    </main>
  )
}

export default Login;
