import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/typography.css";
import "../assets/css/variables.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function CreateBattle() {
  // دریافت کتگوری

  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [expireTime, setExpireTime] = useState("");
  const [title, setTitle] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      const accessToken = window.sessionStorage.getItem("access_token");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://redjayco.com/api/list-categories",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        const response = await axios.request(config);
        const categoriesData = Array.isArray(response.data.data.categories)
          ? response.data.data.categories
          : [];
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories([]); // Set to empty array on error
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (id) => {
    setSelectedCategoryId(id);
  };
  // ایجاد بتل

  const handleSubmit = (e) => {
    e.preventDefault();

    // Combine date and time
    const formattedTime = time ? format(time, "HH:mm:ss") : "00:00:00";
    let expireDateTime = `${expireTime} ${formattedTime}`;

    let data = JSON.stringify({
      description: description,
      expire_at_register: expireDateTime,
      category_id: selectedCategoryId,
      cryptocurrency_id: 1, // This should be dynamic if needed
      title: title,
    });

    // دریافت توکن

    const accessToken = window.sessionStorage.getItem("access_token");

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://redjayco.com/api/create-battle",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    axios.request(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Create success!');
          setTimeout(() => {
            window.location.href = '/'; // Redirect to home page
        }, 3000); // Wait for 3 seconds before redirecting
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred. Please try again.');
        }
      });
  };

  // --------------
  return (
    <div className="create-battle-body">
      <main className="main">
        {/*==========section=========*/}
        <section className="create-battle-section">
          {/*create-battle-content*/}
          <div className="create-battle-content">
            {/*player-name*/}
            <h3 className="h3-title-primary-color h3-title u-margin-bottom-small">
              Hello Player0823!
            </h3>
            {/*form*/}
            <form action="#" className="form-v2" onSubmit={handleSubmit}>
              {/*main-title*/}
              <h2 className="h2-title h2-title-white-color u-margin-bottom-medium">
                Create your battle
              </h2>
              {/*sub-title*/}
              <h4 className="h4-title h4-title-white-color u-margin-bottom-super-small">
                Choose your category
              </h4>
              {/*form-group*/}
              <div className="form-v2-group u-margin-bottom-medium">
                {/*wrapper*/}
                <div className="form-v2-group-content-wrapper u-flex-box">
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      className="form-v2-group u-margin-bottom-medium"
                    >
                      {/*wrapper*/}
                      <div className="form-v2-group-content-wrapper u-flex-box">
                        {/*input-for-controlling-the-functionality*/}
                        <input
                          type="radio"
                          className="form-v2-check-input"
                          id={`form-v2-check-input-${category.id}`}
                          name="category"
                          checked={selectedCategoryId === category.id}
                          onChange={() => handleCategoryChange(category.id)}
                          hidden
                        />
                        {/*label*/}
                        <label
                          htmlFor={`form-v2-check-input-${category.id}`}
                          className="form-v2-label"
                        >
                          {category.title}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/*row-wrapper*/}
              <div className="form-group-row-wrapper form-group-row-wrapper-v2 u-margin-bottom-medium u-flex-box u-align-items-center">
                {/*form-group*/}
                <div className="form-v2-group">
                  {/*sub-title*/}
                  <h4 className="h4-title h4-title-white-color u-margin-bottom-super-small">
                    Expire time
                  </h4>
                  {/*date-input*/}
                  <input
                    type="date"
                    id="expire-time"
                    name="expire-time"
                    className="form-v2-date-input"
                    value={expireTime}
                    onChange={(e) => setExpireTime(e.target.value)}
                  />
                </div>
                {<p>Selected expireTime : {expireTime}</p>}
                {/*form-group*/}
                <div className="form-v2-group">
                  {/*sub-title*/}
                  <h4 className="h4-title h4-title-white-color u-margin-bottom-super-small">
                    Title
                  </h4>
                  {/*text-input*/}
                  <input
                    type="text"
                    className="form-v2-text-input"
                    id="title"
                    placeholder="Enter the title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                {/*form-group*/}
                <div className="form-v2-group">
                  {/*sub-title*/}
                  <h4 className="h4-title h4-title-white-color u-margin-bottom-super-small">
                    Time
                  </h4>
                  {/*time-input*/}
                  <DatePicker
                    selected={time}
                    onChange={(date) => setTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeFormat="HH:mm:ss"
                    dateFormat="HH:mm:ss"
                    timeCaption="Time"
                    className="form-v2-text-input"
                  />
                </div>
              </div>
              {/*form-group*/}
              <div className="form-v2-group u-margin-bottom-medium">
                {/*sub-title*/}
                <h4 className="h4-title h4-title-white-color u-margin-bottom-super-small">
                  Win condition
                </h4>
                {/*text-area*/}
                <textarea
                  className="form-v2-text-area u-margin-bottom-super-small"
                  name="condition"
                  id="win-condition"
                  placeholder="Enter the win condition"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                {/*warning-text*/}
                <p className="warning-text">
                  {/*icon*/}
                  <i className="fa-solid fa-circle-question"></i>
                  The conditions you specify must be between two options
                </p>
              </div>
              {/*create-button*/}
              <button
                type="submit"
                className="form-v2-btn u-margin-bottom-medium u-flex-box u-align-items-center u-justify-content-center"
              >
                Create
              </button>
            </form>
          </div>
        </section>
      </main>
      <ToastContainer toastClassName="toast-right" />
    </div>
  );
}

export default CreateBattle;
